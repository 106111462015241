<template>
  <form
    id="customer_form"
    data-vv-scope="labor-exange-form"
    @submit.prevent="validateForm('labor-exange-form')"
  >
    <v-row justify="center">
      <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown && type === 'month'">
        <span style="color: var(--v-primary-base)">{{
          $t("appointments.month")
        }}</span>
      </v-col>
      <v-col v-if="type === 'month'" cols="12" md="4" lg="3">
        <v-date-picker
          :allowed-dates="allowedDatesMonth"
          :min="today"
          first-day-of-week="1"
          color="primary"
          v-model="month"
          no-title
          type="month"
          @input="error_text = false"
        ></v-date-picker>
      </v-col>
      <v-col v-if="type === 'month'" cols="12" md="7" lg="5"
        ><span
          v-if="!$vuetify.breakpoint.smAndDown"
          style="color: var(--v-primary-base)"
          v-bind:class="[
            {
              'error--text': error_text,
            },
          ]"
          >{{ $t("appointments.month") }}</span
        >
        <div>
          <label>{{ $t("appointments.availability") }}</label>
          <v-textarea
            outlined
            v-model="customer.availability"
            rows="3"
            style="border-radius: 10px; height: 100px"
          ></v-textarea>
        </div>
        <div
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'color: var(--v-primary-base); margin-top: 0px'
              : 'color: var(--v-primary-base); margin-top: 65px'
          "
        >
          <v-checkbox
            v-if="!skip_validate"
            v-model="selected"
            v-validate="'required|is:true'"
            true-value="true"
            data-vv-name="share"
            false-value="false"
            :label="$t('appointments.check_consent')"
            :error-messages="errors.collect('labor-exange-form.share')"
            on-icon="mdi-check-circle"
            off-icon="mdi-circle-outline"
            ><template v-slot:label>
              <div style="margin-top: 6px">
                {{ $t("check_condicions") }}
                <a
                  @click.stop
                  target="blank"
                  :href="studio.value == 'link' ? link.value : URL"
                >
                  {{ $t("check_condicions_links") }}
                </a>
              </div>
            </template></v-checkbox
          >
        </div>
      </v-col>
      <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown && type === 'week'">
        <span
          style="color: var(--v-primary-base)"
          v-bind:class="[
            {
              'error--text': error_text,
            },
          ]"
          >{{ $t("appointments.week") }}</span
        >
      </v-col>
      <v-col v-if="type === 'week'" cols="12" md="4" lg="3">
        <v-date-picker
          :allowed-dates="allowedDates"
          :min="today"
          first-day-of-week="1"
          data-vv-validate-on="blur"
          v-validate="'required'"
          :error-messages="errors.collect('customer-form.week')"
          data-vv-name="week"
          color="primary"
          no-title
          hide-details
          range
          v-model="range"
        ></v-date-picker>
      </v-col>
      <v-col v-if="type === 'week'" cols="12" md="7" lg="5"
        ><span
          v-if="!$vuetify.breakpoint.smAndDown"
          style="color: var(--v-primary-base)"
          v-bind:class="[
            {
              'error--text': error_text,
            },
          ]"
        >
          {{ $t("appointments.week") }}
        </span>
        <div>
          <label>{{ $t("appointments.availability") }}</label>
          <v-textarea
            outlined
            v-model="customer.availability"
            rows="3"
            style="border-radius: 10px; height: 100px"
          ></v-textarea>
        </div>
        <div
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'color: var(--v-primary-base); margin-top: 0px'
              : 'color: var(--v-primary-base); margin-top: 65px'
          "
        >
          <v-checkbox
            v-if="!skip_validate"
            v-model="selected"
            v-validate="'required|is:true'"
            true-value="true"
            data-vv-name="share"
            false-value="false"
            :label="$t('appointments.check_consent')"
            :error-messages="errors.collect('labor-exange-form.share')"
            on-icon="mdi-check-circle"
            off-icon="mdi-circle-outline"
          >
            <template v-slot:label>
              <div style="margin-top: 6px">
                {{ $t("check_condicions") }}
                <a
                  @click.stop
                  target="blank"
                  :href="studio.value == 'link' ? link.value : URL"
                >
                  {{ $t("check_condicions_links") }}
                </a>
              </div>
            </template></v-checkbox
          >
        </div>
      </v-col>
    </v-row>
    <div class="pa-3 text-right">
      <v-btn
        color="primary"
        @click="$emit('back')"
        style="height: 30px; width: 100px"
        outlined
      >
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("back") }}
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        style="height: 30px; width: 100px"
        elevation="0"
        class="ml-2"
      >
        {{ $t("next") }}
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";
import * as moment from "moment";

export default {
  name: "LaborExange",
  props: ["type", "skip_validate"],
  data() {
    return {
      range_min: null,
      range_max: null,
      month: null,
      today: null,
      selected: false,
      dates: [],
      datesTattooer: [],
      error_text: false,
    };
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");
    if (this.$store.getters["auth/isTattooer"]) {
      this.dates = this.customerState.studio.vacations;
    } else {
      this.fetchAll();
    }
    /*this.fetchAllVacationsTattooer(
      this.customerState.tattooer.id,
      this.customerState.studio.id
    );*/
  },
  computed: {
    range: {
      get() {
        return [this.range_min, this.range_max];
      },
      set(value) {
        this.range_max = value;
        this.range_min = value;
        this.error_text = false;
        value = new Date(value[0]);
        value.setDate(value.getDate() + 1 - value.getDay());
        this.range_min = [
          `${value.getFullYear()}-${
            value.getMonth() + 1 >= 10
              ? value.getMonth() + 1
              : "0" + (value.getMonth() + 1)
          }-${value.getDate() >= 10 ? value.getDate() : "0" + value.getDate()}`,
        ];
        value.setDate(value.getDate() + 6);
        this.range_max = [
          `${value.getFullYear()}-${
            value.getMonth() + 1 >= 10
              ? value.getMonth() + 1
              : "0" + (value.getMonth() + 1)
          }-${value.getDate() >= 10 ? value.getDate() : "0" + value.getDate()}`,
        ];
      },
    },
    ...mapState("inscriptions", ["customerState"]),

    customer: {
      get() {
        console.log(this.customerState);
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },
    studio() {
      let policy = this.customerState.studio.user.setting.find((x) => {
        return x.value == "link";
      });
      console.log(policy);

      return policy ? policy : "none";
    },

    link() {
      let policy = this.customerState.studio.user.setting.find((x) => {
        return x.key == "link_appointment";
      });
      console.log(policy);
      return policy;
    },
    URL() {
      return `${window.location.origin}/?#/inscription/policy/studio/${this.$route.params.token}`;
    },
  },
  methods: {
    ...mapActions("close_days", ["getAll"]),
    fetchAll() {
      this.getAll().then((data) => {
        if (data) {
          this.dates = data;
          console.log(this.dates);
        }
      });
    },
    ...mapActions("close_days_tattooer", ["getAllTattooer"]),
    fetchAllVacationsTattooer(tattooer, studio) {
      this.getAllTattooer({ tattooer_id: tattooer, studio_id: studio }).then(
        (data) => {
          if (data) {
            this.datesTattooer = data;
            console.log(this.datesTattooer);
          }
        }
      );
    },
    allowedDates(date) {
      let a = this.dates.find((x) => {
        return x.start_date <= date && x.end_date >= date;
      });

      if (a) return false;
      if (this.customer.tattooer.id !== -1)
        if (this.customer.tattooer.vacation_tattooer.length > 0) {
          let t = this.customer.tattooer.vacation_tattooer.find((x) => {
            return x.start_date <= date && x.end_date >= date;
          });
          if (t) {
            //console.log(t);
            return false;
          }
        }

      // console.log("%c%s", "color: #733d00", "select", this.customer.studio);
      if (this.customer.studio) {
        let filter = this.filter(date);

        //console.log("%c%s", "color: #0088cc", "TENEMOS FILTRO", filter);
        if (filter) {
          //console.log("%c%s", "color: #00bf00", "ENTRAMOS A COMPORVAR HORARIO");
          return this.horario(date).length;
        }
      }
      return false;
    },
    allowedDatesMonth(date) {
      if (this.customer.tattooer.id === -1) return true;
      //console.log("%c%s", "color: #733d00", date);
      if (this.customer.studio) {
        let a = this.customer.studio.tattooer_studios.find((element) => {
          if (element.studio_id !== this.customer.studio.id) return false;
          if (element.tattooer_id !== this.customer.tattooer.id) return false;
          let d = date.split("-");
          let start = new Date(d[0], +d[1] - 1, 1, 0, 0); //.getDate();
          //console.log("%c%s", "color: #917399", start.getTime());
          let end = new Date(d[0], +d[1], 0, 0, 0); //.getDate();
          //console.log("%c%s", "color: #d90000", end.getTime());
          let f = new Date(element.from); //.getTime();
          //console.log("%c%s", "color: #ffa640", f.getTime());

          let b = this.dates.find((x) => {
            let sd = x.start_date.split("-");
            let ed = x.end_date.split("-");
            let s = new Date(sd[0], +sd[1] - 1, sd[2], 0, 0, 0);
            let e = new Date(ed[0], +ed[1] - 1, ed[2], 0, 0, 0);
            start.setHours(0, 0, 0, 0);
            end.setHours(0, 0, 0, 0);
            //console.log("==============");
            //console.log("%c%s", "color: #d90000", start.getTime());
            //console.log("%c%s", "color: #d90", s.getTime());
            //console.log("%c%s", "color: #d90000", end.getTime());
            //console.log("%c%s", "color: #d90", e.getTime());
            return (
              s.getTime() <= start.getTime() && e.getTime() >= end.getTime()
            );
          });
          if (b) {
            console.log(b);
            return false;
          }
          if (element.to) {
            let t = new Date(element.to); //.getTime();
            //console.log("%c%s", "color: #00b300", t.getTime());
            // comprovar que estigui al interval
            // console.log("FROM", f, "TO", t);
            // console.log("START", start, "END", end);
            return (
              start.getTime() <= t.getTime() && end.getTime() >= f.getTime()
            );
          } else {
            //comprovar que sigui mes gran que from
            return f.getTime() <= end.getTime();
          }
        });
        console.log(a);

        if (a) {
          // console.log(date, true);
          return true;
        }
      }
      console.log(date, false);
      return false;
    },
    filter(date) {
      //console.log("%c%s", "color: #733d00", "date", date);
      if (this.customer.tattooer.id !== -1)
        return this.customer.studio.tattooer_studios.find((element) => {
          if (element.studio_id !== this.customer.studio.id) {
            return false;
          }
          if (element.to) {
            // comprovar que estigui al interval
            return element.from <= date && date <= element.to;
          } else {
            //comprovar que sigui mes gran que from
            return element.from <= date;
          }
        });
      return true;
    },

    horario(date) {
      if (date) {
        return this.timeTable(date)[this.dayOfWeek(date)];
      }
      return null;
    },
    timeTable(date) {
      /*if (this.filter(date).tattooer_timetable)
        return this.filter(date).tattooer_timetable;*/
      return this.customer.studio.timetable;
    },
    dayOfWeek(date) {
      let d = new Date(date);

      d = d.getDay() - 1;
      if (d < 0) d = 6;
      return d;
    },
    async validateForm(scope) {
      console.log("validate");
      if (this.skip_validate) {
        this.$emit("validated");
        return;
      }
      let selected = true;
      console.log(this.type, this.range_min, this.range_max);
      if (this.type === "week" && !this.range_min) {
        selected = false;
        this.error_text = true;
      }
      if (this.type === "month" && !this.month) {
        selected = false;
        this.error_text = true;
      }
      this.$validator.validateAll(scope).then((result) => {
        if (result && selected) {
          if (this.type === "week") {
            this.$set(this.customer, "week", this.range);
          } else {
            this.$set(this.customer, "month", this.month);
          }
          console.log("go");
          this.$emit("validated");
        }
      });
    },
  },
};
</script>
<style lang="sass">
#customer_form
  .v-input--selection-controls__input
    margin-top: 4px !important
  label.v-label
    color: var(--v-primary-base) !important
    font-size: 14px !important
  .v-messages
    display: none
</style>