var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"id":"customer_form","data-vv-scope":"labor-exange-form"},on:{"submit":function($event){$event.preventDefault();return _vm.validateForm('labor-exange-form')}}},[_c('v-row',{attrs:{"justify":"center"}},[(_vm.$vuetify.breakpoint.smAndDown && _vm.type === 'month')?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticStyle:{"color":"var(--v-primary-base)"}},[_vm._v(_vm._s(_vm.$t("appointments.month")))])]):_vm._e(),(_vm.type === 'month')?_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDatesMonth,"min":_vm.today,"first-day-of-week":"1","color":"primary","no-title":"","type":"month"},on:{"input":function($event){_vm.error_text = false}},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}})],1):_vm._e(),(_vm.type === 'month')?_c('v-col',{attrs:{"cols":"12","md":"7","lg":"5"}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('span',{class:[
          {
            'error--text': _vm.error_text,
          } ],staticStyle:{"color":"var(--v-primary-base)"}},[_vm._v(_vm._s(_vm.$t("appointments.month")))]):_vm._e(),_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("appointments.availability")))]),_c('v-textarea',{staticStyle:{"border-radius":"10px","height":"100px"},attrs:{"outlined":"","rows":"3"},model:{value:(_vm.customer.availability),callback:function ($$v) {_vm.$set(_vm.customer, "availability", $$v)},expression:"customer.availability"}})],1),_c('div',{style:(_vm.$vuetify.breakpoint.smAndDown
            ? 'color: var(--v-primary-base); margin-top: 0px'
            : 'color: var(--v-primary-base); margin-top: 65px')},[(!_vm.skip_validate)?_c('v-checkbox',{directives:[{name:"validate",rawName:"v-validate",value:('required|is:true'),expression:"'required|is:true'"}],attrs:{"true-value":"true","data-vv-name":"share","false-value":"false","label":_vm.$t('appointments.check_consent'),"error-messages":_vm.errors.collect('labor-exange-form.share'),"on-icon":"mdi-check-circle","off-icon":"mdi-circle-outline"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticStyle:{"margin-top":"6px"}},[_vm._v(" "+_vm._s(_vm.$t("check_condicions"))+" "),_c('a',{attrs:{"target":"blank","href":_vm.studio.value == 'link' ? _vm.link.value : _vm.URL},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.$t("check_condicions_links"))+" ")])])]},proxy:true}],null,false,3144153536),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown && _vm.type === 'week')?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{class:[
          {
            'error--text': _vm.error_text,
          } ],staticStyle:{"color":"var(--v-primary-base)"}},[_vm._v(_vm._s(_vm.$t("appointments.week")))])]):_vm._e(),(_vm.type === 'week')?_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('v-date-picker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"allowed-dates":_vm.allowedDates,"min":_vm.today,"first-day-of-week":"1","data-vv-validate-on":"blur","error-messages":_vm.errors.collect('customer-form.week'),"data-vv-name":"week","color":"primary","no-title":"","hide-details":"","range":""},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1):_vm._e(),(_vm.type === 'week')?_c('v-col',{attrs:{"cols":"12","md":"7","lg":"5"}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('span',{class:[
          {
            'error--text': _vm.error_text,
          } ],staticStyle:{"color":"var(--v-primary-base)"}},[_vm._v(" "+_vm._s(_vm.$t("appointments.week"))+" ")]):_vm._e(),_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("appointments.availability")))]),_c('v-textarea',{staticStyle:{"border-radius":"10px","height":"100px"},attrs:{"outlined":"","rows":"3"},model:{value:(_vm.customer.availability),callback:function ($$v) {_vm.$set(_vm.customer, "availability", $$v)},expression:"customer.availability"}})],1),_c('div',{style:(_vm.$vuetify.breakpoint.smAndDown
            ? 'color: var(--v-primary-base); margin-top: 0px'
            : 'color: var(--v-primary-base); margin-top: 65px')},[(!_vm.skip_validate)?_c('v-checkbox',{directives:[{name:"validate",rawName:"v-validate",value:('required|is:true'),expression:"'required|is:true'"}],attrs:{"true-value":"true","data-vv-name":"share","false-value":"false","label":_vm.$t('appointments.check_consent'),"error-messages":_vm.errors.collect('labor-exange-form.share'),"on-icon":"mdi-check-circle","off-icon":"mdi-circle-outline"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticStyle:{"margin-top":"6px"}},[_vm._v(" "+_vm._s(_vm.$t("check_condicions"))+" "),_c('a',{attrs:{"target":"blank","href":_vm.studio.value == 'link' ? _vm.link.value : _vm.URL},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.$t("check_condicions_links"))+" ")])])]},proxy:true}],null,false,3144153536),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)]):_vm._e()],1),_c('div',{staticClass:"pa-3 text-right"},[_c('v-btn',{staticStyle:{"height":"30px","width":"100px"},attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$emit('back')}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")],1),_c('v-btn',{staticClass:"ml-2",staticStyle:{"height":"30px","width":"100px"},attrs:{"color":"primary","type":"submit","elevation":"0"}},[_vm._v(" "+_vm._s(_vm.$t("next"))+" "),_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }